import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { ArticleType } from './ArticleType'
import { MetavalueStore } from './Metavalue'

export class Configuration extends Model {
  static backendResourceName = 'configuration'

  @observable name = ''
  @observable version = 1

  // Relations
  @observable articleType = this.relation(ArticleType)
  @observable metavalues = this.relation(MetavalueStore)
}

export class ConfigurationStore extends Store {
  static backendResourceName = 'configuration'

  Model = Configuration
}

import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Metafield } from './Metafield'
import { Classification } from './Classification'
import { ArticleType } from './ArticleType'
import { LoadCarrier } from './LoadCarrier'
import { Configuration } from './Configuration'

export class Metavalue extends Model {
  static backendResourceName = 'metavalue'

  @observable id = null

  @observable value = null
  @observable file = null

  // Relations
  @observable metafield = this.relation(Metafield)
  @observable entryClassification = this.relation(Classification)
  @observable entryArticleType = this.relation(ArticleType)
  @observable entryLoadCarrier = this.relation(LoadCarrier)
  @observable entryConfiguration = this.relation(Configuration)
}

export class MetavalueStore extends Store {
  static backendResourceName = 'metavalue'

  Model = Metavalue
}

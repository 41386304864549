import React from 'react'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { t } from 'i18next'
import { AdminOverview, ItemButton } from '@code-yellow/spider'
import { ArticleType } from 'store/ArticleType'
import { Configuration, ConfigurationStore } from 'store/Configuration'
import { Table } from 'semantic-ui-react'

const StyledTableRow = styled(Table.Row)`
  position: relative;

  ${({ activeConfig }) => activeConfig && `
    background: #e0e1e2 !important;
    background-color: #e0e1e2 !important;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 0.75em);
      width: 0;
      height: 0;
      border-top: 0.75em solid transparent;
      border-right: 0.75em solid #fff;
      border-bottom: 0.75em solid transparent;
      z-index: 1;
    }
  `}
`

const StyledTable = styled(Table)`
  ${({ sidebar }) => sidebar && `
    margin-top: 25px !important;

    tr > *:first-child { padding-left: 25px !important; }
    tr > *:last-child { padding-right: 25px !important; }
  `}
`


// interface ConfigurationOverviewProps extends AdminOverviewProps {
//   target: ArticleType,
//   trigger: (triggerProps: { [key: string]: any }) => React.ReactNode;
//   onClose?: () => void;
// }

@observer
export default class ConfigurationOverview extends AdminOverview {
  static propTypes = {
    target: PropTypes.instanceOf(ArticleType).isRequired,
    store: PropTypes.instanceOf(ConfigurationStore),
    selectedConfiguration: PropTypes.instanceOf(Configuration),
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    isSidebar: PropTypes.bool,
  }

  Model = Configuration
  TableRow = StyledTableRow
  Table= StyledTable

  store = null
  bindUrlParams = false
  fetchOnMount = false
  myFilterKey = 'tracy-configuration-overview'

  tableProps() {
    return {
      ...super.tableProps,
      basic: 'very',
      sidebar: this.props.isSidebar,
    }
  }

  rowProps(item, i) {
    return {
      ...super.rowProps(item, i),
      activeConfig: item.id === this.props.selectedConfiguration?.id,
      'data-test-configuration': item?.id ?? i,
    }
  }

  settings = [
    'name',
    {
      label: t('configuration.field.version.label'),
      attr: ({ version }) => `v${version}`,
      sortKey: undefined,
      collapsing: true
    },
    { collapsing: true },
  ]

  sidebars = []

  @computed get buttons() {
    return [
      (configuration) => {
        return (
          <ItemButton compact
            data-test-edit-configuration={configuration.id}
            primary={this.props.selectedConfiguration?.id === configuration.id}
            label='Edit configuration'
            icon='edit'
            onClick={() => this.props.onEdit?.(configuration)}
          />
        )
      },
      (configuration) => {
        return (
          <ItemButton compact
            data-test-delete-configuration={configuration.id}
            primary={this.props.selectedConfiguration?.id === configuration.id}
            label='Delete configuration'
            icon='delete'
            onClick={() => this.props.onDelete?.(configuration)}
          />
        )
      },
    ]
  }

  constructor(props) {
    const res = super(props)

    // Add support for store passthrough
    if ('store' in props) {
      this.store = props.store
    } else {
      this.store = new ConfigurationStore({ relations: ['articleType'] })
    }

    return res
  }

  render() {
    return this.renderOverviewTable()
  }
}

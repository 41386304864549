import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { Metavalues } from 'component/Metafields'
import { EmptyMessageContainer } from 'component/AdminOverview'
import { Configuration } from 'store/Configuration'
import { t } from 'i18next'
import styled from 'styled-components'
import { SaveButton, TargetTextInput } from '@code-yellow/spider'
import { showSaveNotification } from 'helpers/notification'

const FullEmptyMessageContainer = styled(EmptyMessageContainer)`
  margin: 0 !important;
  padding: 25px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.2);
`

@observer
export default class Configurator extends Component {
  static propTypes = {
    configuration: PropTypes.instanceOf(Configuration).isRequired,
  }

  render() {
    const { configuration } = this.props
    return !configuration?.id ? (
      <FullEmptyMessageContainer>{t('configuration.edit.noConfigurationSelected')}</FullEmptyMessageContainer>
    ) : (
      <Form>
        <h1>{t('configuration.edit.title')} - {this.props.configuration?.name}</h1>
        <TargetTextInput
          target={configuration}
          name='name'
        />
        <h3>Configuration</h3>
        <Metavalues model={this.props.configuration} />
        <SaveButton onClick={() => configuration.save({ relations: ['metavalues'] }).then(showSaveNotification)} />
      </Form>
    )
  }
}
